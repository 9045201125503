<template>
  <div class="external-transaction-activity-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="訂閱合約名稱" prop="name" align="center" />
      <BaseElTableColumn label="是否有訂閱方案選用" prop="isSelect" align="center" width="140">
        <template slot-scope="scope">
          <Tag :type="selectedStatus(get(scope.row, 'isSelect'), 'tagType')">{{ selectedStatus(get(scope.row, 'isSelect'), 'label') }}</Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="預覽選用的訂閱方案" prop="isSelect" align="center" width="140">
        <template slot-scope="scope">
          <BaseElButton v-if="scope.row.isSelect" type="text" @click="onViewContract(scope.row)">預覽</BaseElButton>
          <span v-else>-</span>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="操作" align="center" fixed="right" width="140">
        <template slot-scope="scope">
          <TableEditBtnGroup
            copyBtn="查看"
            :hideCopy="!scope.row.isSelect"
            :hideDelete="scope.row.isSelect"
            :hideEdit="scope.row.isSelect"
            @edit="onEdit(scope.row)"
            @delete="onDelete(scope.row)"
            @copy="onView(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>
    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteSubscribeContract"
    />
    <SubscribePlanDialog
      v-if="modal.subscribePlan"
      type="合約"
      :selectItem="selectRow"
      @close="modal.subscribePlan = false"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref } from 'vue'
import dayjs from '@/lib/dayjs'
import { get, map } from 'lodash'
import EmptyBlock from '@/components/EmptyBlock.vue'
import Tag from '@/components/Tag/Tag.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import { DeleteSubscribeContract } from '@/api/subscribe'
import { useRouter } from 'vue-router/composables'
import { subscribeUseStatusConfig } from '@/config/subscribe'
import store from '@/store'
import SubscribePlanDialog from '@/components/Subscribe/SubscribePlanDialog.vue'

export default defineComponent({
  name: 'SubscribeContractTable',
  components: { EmptyBlock, Tag, DeleteDialog, SubscribePlanDialog },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
      subscribePlan: false,
    })
    const shopId = computed(() => store.getters.shop)

    const displayData = computed(() => {
      return map(props.tableData, (item) => {
        const { id, name, content, planContracts } = item
        return {
          id,
          name,
          content,
          planContracts,
          isSelect: !!planContracts.length,
        }
      })
    })
    const selectedStatus = (status, attr) => {
      return get(subscribeUseStatusConfig[status], attr)
    }
    const deleteSubscribeContract = async () => {
      const [, err] = await DeleteSubscribeContract({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('刪除成功！')
      modal.delete = false
      emit('refresh')
    }

    const onDelete = async (row) => {
      selectRow.value = row
      modal.delete = true
    }
    const onViewContract = (row) => {
      selectRow.value = row
      modal.subscribePlan = true
    }
    const onView = (row) => {
      router.push({
        name: 'SubscribeContractEdit',
        params: {
          id: row.id,
        },
        query: {
          view: true,
        },
      })
    }
    const onEdit = (row) => {
      router.push({
        name: 'SubscribeContractEdit',
        params: {
          id: row.id,
        },
        query: {
          edit: true,
        },
      })
    }

    return {
      modal,
      displayData,
      get,
      dayjs,
      onEdit,
      onDelete,
      onViewContract,
      onView,
      deleteSubscribeContract,
      selectedStatus,
      selectRow,
    }
  },
})
</script>
